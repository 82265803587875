import svgIcons from '@/assets/svg/icons';
import ConfirmationModal from '@/components/molecules/ConfirmationModal';
import { spinAnimation } from '@/constants/ui';
import useReinviteMemberToOrganization from '@/hooks/organization/reinviteMemberToOrganization';
import { TUser } from '@/types/User.types';
import { Flex, Icon, Text } from '@chakra-ui/react';
import React, { useState } from 'react';

const OrgMemberPendingReinvite = ({ user }: { user: TUser }) => {
  const [isReinviteModalOpened, setIsReinviteModalOpened] = useState(false);

  const { mutate: reinviteMemberToOrganization, isPending } = useReinviteMemberToOrganization();

  return (
    <Flex
      flexDir={'row'}
      alignItems={'center'}
      width={'50%'}
      cursor={isPending ? 'not-allowed' : 'pointer'}
      onClick={isPending ? () => {} : () => setIsReinviteModalOpened(true)}
      role={isPending ? 'none' : 'group'} // removes group hover animation while the request is pending
    >
      <Text color={'background.blueGray'} _groupHover={{ color: 'text.mediumBlue', fontWeight: 600 }}>
        Pending
      </Text>
      <Icon
        animation={isPending ? spinAnimation : 'none'}
        color="background.blueGray"
        _groupHover={{ color: 'text.mediumBlue' }}
        marginTop={'3px'}
        marginLeft={'5px'}
      >
        <svg width={'34'} height={'34'} viewBox={`0 0 20 20`} xmlns="http://www.w3.org/2000/svg">
          <path d={svgIcons.reload} fill="currentColor" />
        </svg>
      </Icon>

      {/* Reinvite modal */}
      <ConfirmationModal
        title="Confirm Reinvite"
        description={`Send another invite to ${user.firstName ?? user.user_metadata.firstName} ${user.lastName ?? user.user_metadata.lastName}?`}
        confirmButtonText="Reinvite"
        isOpen={isReinviteModalOpened}
        onClose={() => setIsReinviteModalOpened(false)}
        onConfirm={() => {
          reinviteMemberToOrganization(user.user_id);
          setIsReinviteModalOpened(false);
        }}
      />
    </Flex>
  );
};

export default OrgMemberPendingReinvite;
