import CustomButton from '@/components/atoms/CustomButton';
import ModalContainer from '@/components/molecules/ModalContainer';
import { MAX_CHARS_IN_NOTE_CONTENT, MIN_CHARS_IN_NOTE_CONTENT } from '@/constants';
import { Input, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface Props<T> {
  title: string;
  description: string;
  inputPlaceholder: string;
  entityToEdit: T | null;
  setEntityToEdit: Dispatch<SetStateAction<T | null>>;
  editMutation: ({ entityId, content }: { entityId: string; content: string }) => void;
  isEditPending: boolean;
}

const observationSchema = yupResolver(
  yup.object().shape({
    content: yup
      .string()
      .required('Content is required.')
      .min(MIN_CHARS_IN_NOTE_CONTENT, `Content needs to be at least ${MIN_CHARS_IN_NOTE_CONTENT} characters long.`)
      .max(MAX_CHARS_IN_NOTE_CONTENT, `Content length cannot exceed ${MAX_CHARS_IN_NOTE_CONTENT} characters.`),
  }),
);

const EditNoteModal = <T,>({
  title,
  description,
  inputPlaceholder,
  entityToEdit,
  setEntityToEdit,
  editMutation,
  isEditPending,
}: Props<T & { content: string; _id: string }>) => {
  const { register, formState, handleSubmit, reset, setValue, watch } = useForm({
    mode: 'onChange',
    resolver: observationSchema,
  });

  const entityContentFormValue = watch('content');

  useEffect(() => {
    if (entityToEdit) {
      setValue('content', entityToEdit.content);
    }
  }, [entityToEdit]);

  const { isValid: isEditEntityValid, errors: editObservationErrors } = formState;

  const onSubmit = async ({ content }: { content: string }) => {
    if (entityToEdit) {
      await editMutation({ entityId: entityToEdit._id, content });

      setEntityToEdit(null);
      reset();
    }
  };

  return (
    <ModalContainer
      isOpen={!!entityToEdit}
      onClose={() => setEntityToEdit(null)}
      width="420px"
      height="400px"
      backgroundColor="white"
    >
      <VStack>
        <Text variant={'loraTitle'} textAlign={'center'} fontSize={'26px'} marginTop={'30px'}>
          {title}
        </Text>
        <Text variant={'urbanistSemiBold'} color={'text.mediumGray'} maxWidth={'300px'} fontWeight={500}>
          {description}
        </Text>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', marginTop: '20px' }}>
          <VStack spacing={'20px'} width={'100%'}>
            <VStack width={'100%'} alignItems={'flex-start'} spacing={0}>
              <Input
                placeholder={inputPlaceholder}
                as={'textarea'}
                className="custom-input"
                style={{ minHeight: '150px', maxHeight: '350px' }}
                {...register('content')}
              />
              <Text color={'red.500'} fontSize={'14px'}>
                {editObservationErrors.content?.message ?? ''}
              </Text>
            </VStack>
            <CustomButton
              width={'100%'}
              label="Save"
              buttonType="submit"
              backgroundColor="primary.500"
              hoverBackgroundColor="primary.600"
              labelColor="white"
              labelHoverColor="white"
              disabled={!isEditEntityValid || entityContentFormValue === entityToEdit?.content}
              disabledColor="primary.400"
              isLoading={isEditPending}
            />
          </VStack>
        </form>
      </VStack>
    </ModalContainer>
  );
};

export default EditNoteModal;
