import queryKeys from '@/constants/queryKeys';
import useToast from '@/hooks/useToast';
import { TCustomAxiosError } from '@/services/api';
import { createNoteForMember, deleteNote, editNote, getNotesForSelf } from '@/services/api/requests/notes';
import { TNoteType } from '@/types/Note.types';
import { UseMutateFunction, useMutation, useQuery } from '@tanstack/react-query';

// Notes
const useOwnNotes = () => {
  const { isLoading, data, isError, error, isRefetching, refetch } = useQuery({
    queryKey: [queryKeys.notes.self],
    staleTime: 0,
    queryFn: async () => {
      const response = await getNotesForSelf();

      return response;
    },
  });

  return { isLoading, data, isError, error, isRefetching, refetch };
};

const useEditMemberNote = (
  onSuccessCallback?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<void, TCustomAxiosError, { noteId: string; newContent: string }, unknown>;
} => {
  const toast = useToast();

  const { isPending, mutate, isError } = useMutation({
    mutationFn: async ({ noteId, newContent }: { noteId: string; newContent: string }) => {
      editNote(noteId, newContent);
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Member Note edited successfully.',
      });

      onSuccessCallback?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Member Note edit error',
        description: error.errors[0].message,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  return { mutate, isPending, isError };
};

const useDeleteMemberNote = (
  onSuccessCallback?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<void, TCustomAxiosError, string, unknown>;
} => {
  const toast = useToast();

  const { isPending, mutate, isError } = useMutation({
    mutationFn: async (noteId: string) => {
      deleteNote(noteId);
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Member Note deleted successfully.',
      });

      onSuccessCallback?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Member Note deletion error',
        description: error.errors[0].message,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  return { mutate, isPending, isError };
};

const useCreateMemberNote = (
  onSuccessCallback?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<void, TCustomAxiosError, { content: string; createdFor: string; type: TNoteType }, unknown>;
} => {
  const toast = useToast();

  const { isPending, mutate, isError } = useMutation({
    mutationFn: async ({ content, createdFor, type }: { content: string; createdFor: string; type: TNoteType }) => {
      createNoteForMember(content, createdFor, type);
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Member Note created successfully.',
      });

      onSuccessCallback?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast({
        title: 'Member Note creation error',
        description: error.errors[0].message,
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    },
  });

  return { mutate, isPending, isError };
};

export { useOwnNotes, useCreateMemberNote, useEditMemberNote, useDeleteMemberNote };
