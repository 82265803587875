import useOrganizationMemberCares from '@/hooks/membercare/useOrganizationMemberCares';
import { Button, Card, Center, HStack, Image, Spinner, Text, VStack } from '@chakra-ui/react';
import useEmblaCarousel from 'embla-carousel-react';
import React, { useState } from 'react';
import CustomButton from '@/components/atoms/CustomButton';
import Calendly from '@/components/molecules/Calendly';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import useTelemetry from '@/providers/TelemetryProvider/useTelemetry';
import { mixpanelEvents } from '@/constants/mixpanel';
import iconsPng from '@/assets/img/png/icons';
import SvgIcon from '@/components/atoms/SvgIcon';
import svgIcons from '@/assets/svg/icons';
import { useNavigate } from '@tanstack/react-router';
import sharedRoutes from '@/constants/routes/shared';

const CARD_SIZE = '400px';

const UserMemberCares = () => {
  const navigate = useNavigate();
  const { trackEvent } = useTelemetry();

  const { isLoading, data } = useOrganizationMemberCares();

  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  const totalSlides = data?.length || 0;

  const [currentSlide, setCurrentSlide] = useState(0);

  const calendlyLink = data?.[currentSlide]?.calendly_link ?? '';

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    startIndex: 0,
    // watchDrag: () => { }, // disables dragging
  });

  const goToNextSlide = () => {
    setCurrentSlide((prev) => prev + 1);
    emblaApi?.scrollNext();
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((prev) => prev - 1);
    emblaApi?.scrollPrev();
  };

  const isLeftArrowVisible = currentSlide > 0 && totalSlides > 1;
  const isRightArrowVisible = currentSlide < totalSlides - 1 && totalSlides > 1;

  if (isLoading) {
    return (
      <VStack width={'100%'} height={CARD_SIZE} justify={'center'} alignItems={'center'}>
        <Spinner />
      </VStack>
    );
  }

  if (totalSlides === 0) {
    return (
      <VStack width={'100%'} height={CARD_SIZE} justify={'center'} alignItems={'center'}>
        <Card
          size="sm"
          minWidth={'370px'}
          minHeight={'215px'}
          variant={'elevated'}
          borderRadius={'20px'}
          padding={'30px'}
          shadow={'0px 0px 20px 0px rgba(0, 65, 121, 0.15)'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <VStack position={'absolute'} top={-50}>
            <Image alt="notepad" src={iconsPng.notepad} width={100} height={100} />
          </VStack>
          <Text
            variant={'lora'}
            fontSize={'22px'}
            lineHeight={'22px'}
            fontWeight={500}
            color={'text.mediumBlue'}
            fontStyle={'italic'}
            marginTop={'50px'}
          >
            No member cares assigned?
          </Text>

          <CustomButton
            label="Contact us"
            style={{ marginTop: '30px' }}
            backgroundColor="primary.500"
            labelColor="extra.white"
            iconBeforeLabelJSX={<SvgIcon iconPath={svgIcons.email} size={19} color={'white'} />}
            onClick={() => {
              navigate({ to: sharedRoutes.contactSupport });
            }}
          />
        </Card>
      </VStack>
    );
  }

  return (
    <VStack width={'100%'} minH={'500px'}>
      {/* width: 90% -- important to not have the section cover the left-right slider buttons below */}
      <section className="embla" key={'member-cares'} style={{ width: '90%', zIndex: 1 }}>
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {data?.map((mc) => {
              return (
                <div className="embla__slide" key={mc.user_id}>
                  <VStack width={'100%'} alignItems={'center'} paddingY={'18px'}>
                    <Card
                      size="sm"
                      width={CARD_SIZE}
                      minHeight={CARD_SIZE}
                      variant={'elevated'}
                      borderRadius={'8px'}
                      padding={'20px'}
                      shadow={'0px 0px 20px 0px rgba(0, 65, 121, 0.15)'}
                    >
                      <Center flexDirection={'column'} width="100%" paddingBottom={'20px'}>
                        <VStack
                          borderWidth={'4px'}
                          borderRadius={'full'}
                          borderColor={'white'}
                          shadow={'0px 6px 24px 0px rgba(14, 0, 98, 0.2)'}
                          overflow={'hidden'}
                          cursor={'pointer'}
                          _hover={{ boxShadow: '0px 4px 8px 0px rgba(14, 0, 98, 0.2)' }}
                          transition={'box-shadow 0.1s'}
                          onClick={() =>
                            navigate({ to: '/member-care/$memberCareId', params: { memberCareId: mc.user_id } })
                          }
                        >
                          {mc.profilePicture ? (
                            <Image
                              src={mc.profilePicture}
                              alt={mc.firstName ?? mc.user_metadata.firstName}
                              width={120}
                              height={120}
                              borderRadius={'full'}
                              objectFit={'cover'}
                            />
                          ) : (
                            <Center backgroundColor={'#EDE8FD'} height={125} width={125}>
                              <SvgIcon
                                color={'#fff'}
                                iconPath={svgIcons.userSeeThrough}
                                size={65}
                                boxHeight={80}
                                boxWidth={80}
                              />
                            </Center>
                          )}
                        </VStack>
                        <VStack marginTop={'20px'}>
                          <Text
                            variant={'lora'}
                            fontSize={'22px'}
                            lineHeight={'22px'}
                            fontWeight={500}
                            color={'text.darkBlue'}
                            fontStyle={'italic'}
                          >
                            {mc.firstName ?? mc.user_metadata.firstName} {mc.lastName ?? mc.user_metadata.lastName}
                          </Text>
                          <Text fontWeight={700} fontSize={'14px'}>
                            Our Member Support Specialist
                          </Text>
                          <Text
                            fontWeight={500}
                            fontSize={'16px'}
                            lineHeight={'19px'}
                            textAlign={'center'}
                            color={'text.mediumBlue'}
                            paddingX={'10px'}
                          >
                            {mc.bio}
                          </Text>
                        </VStack>
                      </Center>
                    </Card>
                  </VStack>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <CustomButton
        label="Schedule call"
        backgroundColor="primary.500"
        labelColor="extra.white"
        onClick={() => {
          setIsCalendlyOpen(true);
          trackEvent(mixpanelEvents.MEMBER_CARE_CALL_SCHEDULED);
        }}
        width={'300px'}
        style={{ marginTop: '30px' }}
        disabled={calendlyLink === ''}
      />
      {calendlyLink === '' && (
        <Text variant={'urbanistSmall'} color={'text.mediumBlue'}>
          No Calendly Link added
        </Text>
      )}
      <Calendly isOpen={isCalendlyOpen} setIsOpen={setIsCalendlyOpen} url={calendlyLink} />
      <HStack
        width={MAX_CONTAINER_WIDTH}
        height={CARD_SIZE}
        position={'absolute'}
        justifyContent={'space-between'}
        backgroundColor={'transparent'}
        alignItems={'center'}
      >
        {isLeftArrowVisible ? (
          <Button
            onClick={goToPreviousSlide}
            width={'50px'}
            height={'50px'}
            borderRadius={'full'}
            boxShadow={'0px 4px 15px 0px rgba(0, 0, 0, 0.2)'}
            _hover={{ boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.2)' }}
          >
            <ArrowBackIcon color={'primary.600'} boxSize={6} />
          </Button>
        ) : (
          <VStack />
        )}

        {isRightArrowVisible ? (
          <Button
            onClick={goToNextSlide}
            width={'50px'}
            height={'50px'}
            borderRadius={'full'}
            boxShadow={'0px 4px 15px 0px rgba(0, 0, 0, 0.2)'}
            _hover={{ boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.2)' }}
          >
            <ArrowForwardIcon color={'primary.600'} boxSize={6} />
          </Button>
        ) : (
          <VStack />
        )}
      </HStack>
    </VStack>
  );
};

export default UserMemberCares;
