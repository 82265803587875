import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay } from '@chakra-ui/react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onCloseComplete?: () => void;
  children: React.ReactNode;
  footerContent?: React.ReactNode;
  isBlur?: boolean;
  width?: string;
  height?: string;
  backgroundColor?: string;
  closeButtonColor?: string;
  closeOnOverlayClick?: boolean;
};

const ModalContainer = ({
  children,
  footerContent = null,
  isOpen,
  onClose,
  onCloseComplete = () => {},
  isBlur = true,
  width = '100%',
  height = '100vh',
  backgroundColor = 'transparent',
  closeButtonColor = 'black',
  closeOnOverlayClick = true,
}: Props) => {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      onCloseComplete={onCloseComplete}
      isCentered
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay bg="blackAlpha.700" backdropFilter={isBlur ? 'blur(18px)' : ''} />
      <ModalContent borderRadius={'20px'} backgroundColor={backgroundColor} minWidth={width} minHeight={height}>
        <ModalCloseButton size={'lg'} marginTop={'20px'} marginRight={'20px'} color={closeButtonColor} />
        <ModalBody>{children}</ModalBody>
        {footerContent && <ModalFooter>{footerContent}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
};

export default ModalContainer;
