import { useAppStore } from '@/store/useAppStore';
import { isEverythingSetup } from '@/utils/onboardingUtils';
import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/_onboarding')({
  beforeLoad: async ({ context }) => {
    const isOrgAdmin = context.auth?.isAdmin;
    const { onboardingStatuses } = useAppStore.getState()
    
    const isOnboardingCompleted = isEverythingSetup(onboardingStatuses, isOrgAdmin)

    if (isOnboardingCompleted) {
      const { isUserOnboardingCompleted, isOrganizationOnboardingCompleted} = onboardingStatuses;

      if (isOrgAdmin && !isOrganizationOnboardingCompleted) {
        throw redirect({
          to: '/organization/dashboard',
        });
      }

      if (isUserOnboardingCompleted) {
        throw redirect({
          to: '/user/dashboard',
        });
      }

      throw redirect({
        to: '/eula/update',
      });
      
    }
  },
});
