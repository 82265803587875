export const MAX_CONTAINER_WIDTH = '1240px';
export const MAIN_CONTENT_MARGIN_TOP = '70px';

const HEADER_TOP_PADDING = 16;
const HEADER_BUTTON_HEIGHT = 54;

export const HEADER_HEIGHT = HEADER_BUTTON_HEIGHT + HEADER_TOP_PADDING;

export const USER_PROFILE_AVATAR_SIZE = 45;

export const OUTER_CIRCLE_SIZE = 760;
export const CIRCLE_SVG_SIZE = 600;
export const CIRCLE_WIDTH = 2.3;
export const CIRCLES_SVG_DATA = [
  { radius: 130, opacity: 0.8 },
  { radius: 170, opacity: 0.6 },
  { radius: 210, opacity: 0.4 },
  { radius: 250, opacity: 0.2 },
];

export const FORM_FIELD_WIDTH = '300px';
export const CENTERED_FORM_WIDTH = '620px';

export const OBSERVATIONS_NOTES_WIDTH = '500px';

export const TIMELINE_ICON_DIMENSION = 50;
export const TIMELINE_DATE_TEXT_DIMENSION = 55;
export const TIMELINE_ITEMS_GAP = 20;
