import AppPageTemplate from '@/components/templates/AppPageTemplate';
import { createFileRoute, Outlet } from '@tanstack/react-router';

const MemberCareLayout = () => {
  return (
    <AppPageTemplate>
      <Outlet />
    </AppPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_member-care')({
  component: MemberCareLayout,
});
