import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { getAssessmentsResultAverageScoresOrganization } from '@/utils/queryOptions';
import { Spinner, Text, VStack } from '@chakra-ui/react';
import { useAppStore } from '@/store/useAppStore';
import WellnessStateCircle from '@/components/molecules/WellnessStateCircle';
import OrgMembersRoster from '@/modules/organization/OrgMembersRoster';
import { useMemo } from 'react';

const OrganizationDashboard = () => {
  const averageScoresOrganization = useSuspenseQuery(getAssessmentsResultAverageScoresOrganization());

  const userWellnessScoresNoTotal = useMemo(() => {
    const totalRemoved = { ...averageScoresOrganization.data };
    delete totalRemoved.totalScore;
    return totalRemoved;
  }, [averageScoresOrganization.data]);

  const { organization } = useAppStore();

  return (
    <VStack gap={0}>
      <Text marginY={'40px'} variant={'loraTitle'}>
        Breakdown of your team’s strengths and opportunities per category
      </Text>

      {!averageScoresOrganization ? (
        <Text variant={'loraTitle'} marginY={'200px'}>
          None of the org members have taken the screener
        </Text>
      ) : (
        <WellnessStateCircle
          wellnessData={userWellnessScoresNoTotal}
          totalScore={averageScoresOrganization.data.totalScore}
          userAvatar={organization?.logoUrl}
        />
      )}

      <OrgMembersRoster />
    </VStack>
  );
};

export const Route = createFileRoute('/_authenticated/_organization/organization/dashboard')({
  component: OrganizationDashboard,
  loader: async ({ context }) => context.queryClient.ensureQueryData(getAssessmentsResultAverageScoresOrganization()),
  // NOTE: Default pending component can be overridden if you want to show a custom pending states. This is just a showcase
  pendingComponent: () => <Spinner />,
});
