import logoVariants from '@/assets/img/logo';
import iconsPng from '@/assets/img/png/icons';
import svgIcons from '@/assets/svg/icons.ts';
import CustomButton from '@/components/atoms/CustomButton';
import LoadingLogo from '@/components/atoms/LoadingLogo';
import { OBSERVATIONS_NOTES_WIDTH } from '@/constants/dimensions';
import { REFETCH_MILISECONDS } from '@/constants';
import { useOwnNotes, useDeleteMemberNote, useEditMemberNote, useCreateMemberNote } from '@/hooks/notes';
import colors from '@/theme/colors';
import { Center, HStack, Image, Text, VStack } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import ConfirmationModal from '@/components/molecules/ConfirmationModal';
import { TNote } from '@/types/Note.types';
import UserNoteDisplay from './UserNoteDisplay';
import AddNoteModal from './AddNoteModal';
import EditNoteModal from './EditNoteModal';

interface Props {
  memberId: string;
}

const UserNotes = ({ memberId }: Props) => {
  const [addNoteModalOpened, setAddNoteModalOpened] = useState(false);

  const [noteToDelete, setNoteToDelete] = useState<TNote | null>(null);
  const [noteToEdit, setNoteToEdit] = useState<TNote | null>(null);

  const { data: memberNotes, isLoading: isLoadingMemberNotes, isRefetching, refetch } = useOwnNotes();

  const ownNotes = useMemo(() => memberNotes?.filter((note) => note.type === 'PERSONAL'), [memberNotes]);

  const memberCareNotes = useMemo(
    () => memberNotes?.filter((note) => note.type === 'MEMBERCARE_TO_USER'),
    [memberNotes],
  );

  const refetchAfterSomeTime = () => setTimeout(refetch, REFETCH_MILISECONDS);

  const { mutate: deleteMemberNote, isPending: isNoteDeletionPending } = useDeleteMemberNote(refetchAfterSomeTime);

  const deleteSelectedNote = async () => {
    if (noteToDelete) {
      await deleteMemberNote(noteToDelete._id);
      setNoteToDelete(null);
    }
  };
  const { mutate: createMemberNoteMutation, isPending: isAddNotePending } = useCreateMemberNote(refetchAfterSomeTime);
  const createMemberNote = ({ content, memberId }: { content: string; memberId: string }) => {
    createMemberNoteMutation({ content, createdFor: memberId, type: 'PERSONAL' });
  };

  const { mutate: editMemberNoteMutation, isPending: isEditNotePending } = useEditMemberNote(refetchAfterSomeTime);

  const editMemberNote = ({ content, entityId }: { content: string; entityId: string }) => {
    editMemberNoteMutation({ newContent: content, noteId: entityId });
  };

  const isLoading = isLoadingMemberNotes || isRefetching || !memberNotes;

  return (
    <HStack width={'100%'} gap={'50px'} justifyContent={'center'}>
      {/* Own Notes */}
      <VStack>
        <HStack width={OBSERVATIONS_NOTES_WIDTH} justifyContent={'space-between'}>
          <Text variant={'loraSmallTitle'}>My Notes</Text>
          <CustomButton
            isTransparent
            borderHoverColor="transparent"
            label="Add Note"
            onClick={() => setAddNoteModalOpened(true)}
            iconBeforeLabelSvgPath={svgIcons.plus}
            iconBeforeLabelSvgSize={16}
            labelColor="text.mediumBlue"
            iconBeforeLabelSvgColor={colors.text.mediumGray}
            labelHoverColor="text.darkBlue"
          />
        </HStack>

        <VStack
          width={OBSERVATIONS_NOTES_WIDTH}
          height={OBSERVATIONS_NOTES_WIDTH}
          padding={'20px'}
          backgroundColor={'extra.white'}
          boxShadow={'0px 0px 20px 0px #00417926'}
          borderRadius={'12px'}
          position={'relative'}
        >
          {isLoading && (
            <Center
              height={'100%'}
              width={'100%'}
              position={'absolute'}
              backgroundColor={'rgba(255, 255, 255, 0.7)'}
              top={0}
              borderRadius={'12px'}
            >
              <LoadingLogo logoVariant={logoVariants.whiteNoText160x160} logoHeight="90px" logoWidth="90px" />
            </Center>
          )}

          {ownNotes?.length === 0 && (
            <Center height={'100%'} width={'100%'}>
              <VStack>
                <Image src={iconsPng.personReading} width={120} height={120} alt="no-notes" />
                <Text
                  variant={'urbanistSemiBold'}
                  fontWeight={500}
                  color={'text.mediumGray'}
                  marginTop={'20px'}
                  width={'425px'}
                >
                  These notes are <b style={{ color: colors.text.darkBlue }}>visible</b> to both you and your
                  organization's Member Support specialists.
                </Text>
              </VStack>
            </Center>
          )}

          <VStack overflowY={'scroll'} padding={'20px'} gap={'20px'} width={'100%'}>
            {ownNotes?.map((note) => (
              <UserNoteDisplay<TNote>
                key={note._id}
                entity={note}
                onClickEdit={setNoteToEdit}
                onClickDelete={setNoteToDelete}
              />
            ))}
          </VStack>

          <ConfirmationModal
            isOpen={!!noteToDelete}
            onClose={() => setNoteToDelete(null)}
            title="Confirm Deletion"
            confirmButtonText="Delete"
            description={`This will delete the note: \n${noteToDelete?.content}`}
            onConfirm={deleteSelectedNote}
            confirmButtonColor="extra.red"
            confirmLoading={isNoteDeletionPending}
          />
        </VStack>
      </VStack>

      {/* Member Support Notes */}
      <VStack>
        <HStack width={OBSERVATIONS_NOTES_WIDTH} justifyContent={'space-between'} height={'50px'}>
          <Text variant={'loraSmallTitle'}>Member Support Notes</Text>
        </HStack>

        <VStack
          width={OBSERVATIONS_NOTES_WIDTH}
          height={OBSERVATIONS_NOTES_WIDTH}
          padding={'20px'}
          backgroundColor={'extra.white'}
          boxShadow={'0px 0px 20px 0px #00417926'}
          borderRadius={'12px'}
          position={'relative'}
        >
          {isLoading && (
            <Center
              height={'100%'}
              width={'100%'}
              position={'absolute'}
              backgroundColor={'rgba(255, 255, 255, 0.7)'}
              top={0}
              borderRadius={'12px'}
            >
              <LoadingLogo logoVariant={logoVariants.whiteNoText160x160} logoHeight="90px" logoWidth="90px" />
            </Center>
          )}

          {memberCareNotes?.length === 0 && (
            <Center height={'100%'} width={'100%'}>
              <VStack>
                <Image src={iconsPng.notepad} width={120} height={120} alt="no-notes" />
                <Text
                  variant={'urbanistSemiBold'}
                  fontWeight={500}
                  color={'text.mediumGray'}
                  marginTop={'20px'}
                  width={'425px'}
                >
                  No Member Support notes at the moment.
                </Text>
              </VStack>
            </Center>
          )}

          <VStack overflowY={'scroll'} padding={'20px'} gap={'20px'} width={'100%'}>
            {memberCareNotes?.map((note) => (
              <UserNoteDisplay<TNote>
                key={note._id}
                entity={note}
                createdBy={
                  note.createdBy?.firstName ? `${note.createdBy?.firstName} ${note.createdBy?.lastName}` : undefined
                }
              />
            ))}
          </VStack>
        </VStack>
      </VStack>

      <AddNoteModal
        title="Add Note"
        description="Notes are visible to clients."
        inputPlaceholder="Write your note here..."
        addObservationModalOpened={addNoteModalOpened}
        setAddObservationModalOpened={setAddNoteModalOpened}
        memberId={memberId}
        createMutation={createMemberNote}
        isCreationPending={isAddNotePending}
      />

      <EditNoteModal<TNote>
        title="Edit Note"
        description="Notes are visible to clients."
        inputPlaceholder="Write your note here..."
        editMutation={editMemberNote}
        isEditPending={isEditNotePending}
        entityToEdit={noteToEdit}
        setEntityToEdit={setNoteToEdit}
      />
    </HStack>
  );
};

export default UserNotes;
