import logoVariants from '@/assets/img/logo';
import { Image } from '@chakra-ui/react';
import React from 'react';

interface Props {
  logoVariant?: string;
  logoWidth?: string;
  logoHeight?: string;
}

const LoadingLogo = ({ logoVariant = logoVariants.purple252x80, logoWidth = '202px', logoHeight = '64px' }: Props) => {
  return (
    <Image
      animation={'alternate-opacity 0.7s infinite ease-out alternate'}
      src={logoVariant}
      width={logoWidth}
      height={logoHeight}
      alt="my-omnia-logo"
    />
  );
};

export default LoadingLogo;
