import CustomButton from '@/components/atoms/CustomButton';
import ModalContainer from '@/components/molecules/ModalContainer';
import { MAX_CHARS_IN_NOTE_CONTENT, MIN_CHARS_IN_NOTE_CONTENT } from '@/constants';
import { Input, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface Props {
  title: string;
  description: string;
  inputPlaceholder: string;
  memberId: string;
  addObservationModalOpened: boolean;
  setAddObservationModalOpened: Dispatch<SetStateAction<boolean>>;
  createMutation: ({ content, memberId }: { content: string; memberId: string }) => void;
  isCreationPending: boolean;
}

const observationSchema = yupResolver(
  yup.object().shape({
    content: yup
      .string()
      .required('Content is required.')
      .min(MIN_CHARS_IN_NOTE_CONTENT, `Content needs to be at least ${MIN_CHARS_IN_NOTE_CONTENT} characters long.`)
      .max(MAX_CHARS_IN_NOTE_CONTENT, `Content length cannot exceed ${MAX_CHARS_IN_NOTE_CONTENT} characters.`),
  }),
);

const AddNoteModal = ({
  title,
  description,
  inputPlaceholder,
  memberId,
  addObservationModalOpened,
  setAddObservationModalOpened,
  createMutation,
  isCreationPending,
}: Props) => {
  const [] = useState(false);

  const { register, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    resolver: observationSchema,
  });

  const { isValid: isAddObservationValid, errors: addObservationErrors } = formState;

  const onSubmit = async ({ content }: { content: string }) => {
    await createMutation({ content, memberId });

    setAddObservationModalOpened(false);
    reset();
  };

  return (
    <ModalContainer
      isOpen={addObservationModalOpened}
      onClose={() => setAddObservationModalOpened(false)}
      width="420px"
      height="400px"
      backgroundColor="white"
    >
      <VStack>
        <Text variant={'loraTitle'} textAlign={'center'} fontSize={'26px'} marginTop={'30px'}>
          {title}
        </Text>
        <Text variant={'urbanistSemiBold'} color={'text.mediumGray'} maxWidth={'300px'} fontWeight={500}>
          {description}
        </Text>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', marginTop: '20px' }}>
          <VStack spacing={'20px'} width={'100%'}>
            <VStack width={'100%'} alignItems={'flex-start'} spacing={0}>
              <Input
                placeholder={inputPlaceholder}
                as={'textarea'}
                className="custom-input"
                style={{ minHeight: '150px', maxHeight: '350px' }}
                {...register('content')}
              />
              <Text color={'red.500'} fontSize={'14px'}>
                {addObservationErrors.content?.message ?? ''}
              </Text>
            </VStack>
            <CustomButton
              width={'100%'}
              label="Save"
              buttonType="submit"
              backgroundColor="primary.500"
              hoverBackgroundColor="primary.600"
              labelColor="white"
              labelHoverColor="white"
              disabled={!isAddObservationValid}
              disabledColor="primary.400"
              isLoading={isCreationPending}
            />
          </VStack>
        </form>
      </VStack>
    </ModalContainer>
  );
};

export default AddNoteModal;
