import queryKeys from '@/constants/queryKeys';
import { getOwnTimeline } from '@/services/api/requests/timeline';
import { useQuery } from '@tanstack/react-query';

// Timeline
const useOwnTimeline = () => {
  const { isLoading, data, isError, error, isRefetching, refetch } = useQuery({
    queryKey: [queryKeys.timeline.self],
    staleTime: 0,
    queryFn: async () => {
      const response = await getOwnTimeline();

      return response;
    },
  });

  return { isLoading, data, isError, error, isRefetching, refetch };
};

export { useOwnTimeline };
