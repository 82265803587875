import { TTimeline } from '@/types/Timeline.types.ts';
import { api, TCustomAxiosResponse } from '..';

const root = 'v1/timeline';

const endpoints = {
  timeline: `${root}`,
  self: `${root}/self`,
};

export const getOwnTimeline = async (): Promise<TTimeline[]> => {
  const { data } = await api.get<TCustomAxiosResponse<TTimeline[]>>(endpoints.self);
  const notes = data.data;

  return notes;
};
