import CustomButton from '@/components/atoms/CustomButton';
import { Box, Center, HStack, Image, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import UserProfileBox from './UserProfileBox';
import FilePicker from '@/components/atoms/FilePicker';
import svgFiles from '@/assets/svg/svgFiles';

interface UserProfilePhotoBoxProps {
  updateProfilePhoto: (photo: File) => Promise<boolean>;
  initialPreviewUrl?: string;
}

const UserProfilePhotoBox = ({ updateProfilePhoto, initialPreviewUrl = '' }: UserProfilePhotoBoxProps) => {
  const [showUploadButton, setShowUploadButton] = useState(false);
  const inputProfilePhotoRef = useRef<HTMLInputElement>(null);

  const [preview, setPreview] = useState<string | null>(initialPreviewUrl);

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  const hasInitImagePreview = !!preview || !!initialPreviewUrl;
  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);

  const uploadPhotoWithLoading = useCallback(async (file: File | null) => {
    if (!file) return;

    setPreview('');
    setShowUploadButton(true);
    setIsUploadingPhoto(true);

    await updateProfilePhoto(file);
    const previewUrl = URL.createObjectURL(file);

    setIsUploadingPhoto(false);
    setShowUploadButton(false);
    setPreview(previewUrl);
  }, []);

  return (
    <UserProfileBox style={{ marginTop: '40px', marginBottom: '40px' }}>
      <Center>
        <HStack justifyContent={'space-between'} width={'100%'}>
          <Box maxWidth={'180px'}>
            <Text fontSize={'26px'} fontWeight={'bold'} marginBottom={'10px'}>
              Profile Photo
            </Text>
            <Text variant={'urbanistSmall'} color={'text.mediumBlue'}>
              File details: not smaller than 100x100px and not larger than 3MB.
            </Text>
          </Box>
          <Center
            width={190}
            height={190}
            borderRadius={'50%'}
            backgroundImage={preview ?? ''}
            backgroundPosition={'center'}
            backgroundRepeat={'no-repeat'}
            backgroundSize={'cover'}
            borderWidth={5}
            borderColor={'white'}
            boxShadow={'0px 12px 30px 0px #0E00621A'}
            onMouseEnter={() => setShowUploadButton(true)}
            onMouseLeave={() => setShowUploadButton(false)}
            position={'relative'}
            cursor={'pointer'}
            onClick={() => inputProfilePhotoRef.current?.click()}
          >
            <Box position={'absolute'} opacity={showUploadButton ? 1 : 0} transition={'opacity 0.3s'}>
              <FilePicker
                inputRef={inputProfilePhotoRef}
                selectedFile={null}
                setSelectedFile={uploadPhotoWithLoading}
                accept="image/*"
              />
              <CustomButton
                // just sending in an empty function, because <Center> parent handles the click
                onClick={() => {}}
                label={isUploadingPhoto ? 'Uploading...' : 'Upload'}
                backgroundColor="primary.600"
                hoverBackgroundColor="primary.600"
                labelHoverColor="white"
                labelColor="white"
              />
            </Box>

            {!hasInitImagePreview && <Image alt="generic-profile" src={svgFiles.genericUser} height={80} width={80} />}
          </Center>
        </HStack>
      </Center>
    </UserProfileBox>
  );
};

export default UserProfilePhotoBox;
