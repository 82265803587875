import { TNote, TNoteType } from '@/types/Note.types';
import { api, TCustomAxiosResponse } from '..';

const root = 'v1/notes';

const endpoints = {
  notes: `${root}`,
  self: `${root}/user/self`,
};

export const getNotesForSelf = async (): Promise<TNote[]> => {
  const { data } = await api.get<TCustomAxiosResponse<TNote[]>>(endpoints.self);
  const notes = data.data;

  return notes;
};

export const createNoteForMember = async (content: string, createdFor: string, type: TNoteType): Promise<string> => {
  const { data } = await api.post(endpoints.notes, { content, createdFor, type });

  const result = data.data;

  return result;
};

export const editNote = async (noteId: string, newContent: string): Promise<string> => {
  const { data } = await api.put(`${endpoints.notes}/${noteId}`, { content: newContent });

  const result = data.data;

  return result;
};

export const deleteNote = async (noteId: string): Promise<string> => {
  const { data } = await api.delete(`${endpoints.notes}/${noteId}`);

  const result = data.data;

  return result;
};
